<template>
  <div class="error_box">
    <div class="text">404</div>
    <dl class="content">
      <dt>~~访问的页面找不到了啦~~</dt>
      <dd>
        <router-link :to="{ path: '/' }"><i class="fa fa-chevron-circle-left"></i> 返回首页</router-link>
      </dd>
    </dl>
  </div>
</template>

<script>
export default {
  name: "pageError",
  data() {
    return {

    }
  }
}
</script>

<style scoped lang="scss">
  .error_box {
    width: 100%;
    height: 100%;
    background: #00ffba;
    text-align: center;
    .text {
      padding-top: 10vh;
      font-size: 120px;
      font-weight: bold;
      color: #fff;
      text-shadow: 3px 3px 0 #27c492, 5px 3px 0 rgba(0,0,0,.15)
    }
    .content {
      display: block;
      width: 280px;
      height: auto;
      margin: 0 auto;
      font-size: 16px;
      dt {
        height: 40px;
        line-height: 40px;
        color: #fff;
        text-shadow: 1px 0px 0 #27c492, 1px 0px 0 #000
      }
      dd {
        a {
          margin-top: 30px;
          display: inline-block;
          padding: 10px 15px;
          color: #fff;
          background: #ff6600;
          border-radius: 3px;
        }
      }
    }
  }
</style>
